import React from "react";
import Layout from "../components/organisms/layout/layout";
import CustomProductTemplate from "../components/templates/custom-product-template/customProductTemplate";

const CustomProduct = (props) => {
  return (
    <Layout title={`Dicar | Producto personalizado - ${props.pageContext.textTitle}`}>
      <CustomProductTemplate {...props.pageContext} />
    </Layout>
  )
}

export default CustomProduct;