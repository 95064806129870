import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import "./customProductTemplate.scss";

const CustomProductTemplate = (props) => {
  const {
    buttonHref,
    buttonText,
    description,
    examples,
    examplesTitle,
    lists,
    listsTitle,
    textTitle
  } = props;

  let listsArray = [];

  const setListsArray = () => {
    const contentfulLists = JSON.parse(lists.raw).content.slice(0, -1);
    let listToAdd = [];

    contentfulLists.map((list, i) => {
      if (list.nodeType !== "paragraph" || i === 0) {
        listToAdd.push(list);
        if (i === contentfulLists.length - 1) listsArray.push(listToAdd);
      } else {
        listsArray.push(listToAdd);
        listToAdd = [list];
      }
    });

    listsArray = listsArray.map((item) => ({content: item, data: {}, nodeType: "document"}))
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span>{text}</span>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        if (children) return <p className="t-custom-product__desc">{children}</p>
      },
      [BLOCKS.LIST_ITEM]: (_, children) => {
        return <li className="t-custom-product__list-item">{children}</li>
      }
    }
  }

  setListsArray();

  return (
    <div className="t-custom-product">
      <h1 className="t-custom-product__title">
        {textTitle}
      </h1>
      {documentToReactComponents(JSON.parse(description.raw), options)}
      <a
        href={buttonHref}
        className="t-custom-product__button"
      >
        {buttonText}
      </a>
      {listsTitle &&
        <p className="t-custom-product__subtitle">
          {listsTitle}
        </p>
      }
      <div className="t-custom-product__lists">
        {listsArray.map((list, i) => (
          <div key={`${list.length} - ${i}`} className="t-custom-product__lists__list">
            {documentToReactComponents(list, options)}
          </div>
        ))}
      </div>
      <h2 className="t-custom-product__subtitle">
        {examplesTitle}
      </h2>
      <div className="t-custom-product__examples">
        {examples.map((example) => (
          <div
            key={example.image.file.fileName}
            className="t-custom-product__examples__example"
          >
            <div className="t-custom-product__examples__example__image">
              <img
                src={example.image.file.url}
                alt={example.image.file.fileName}
              />
            </div>
            {documentToReactComponents(JSON.parse(example.description.raw), options)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomProductTemplate;